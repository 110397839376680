export class Alert {
  static create( message, empty, title = "Alert", actions = [], after = () => {
  }, html = false ) {
    return new this( message, empty, title, actions, after, html );
  }

  static createEmpty() {
    return this.create( "", true, "Alert", [] );
  }

  static toEmpty( alert ) {
    return this.create( alert.message, true, alert.title, alert.actions, alert.after, alert.html );
  }

  constructor( message, empty, title, actions, after, html ) {
    this.message = message;
    this.empty = empty;
    this.title = title;
    this.actions = actions;
    this.after = after;
    this.html = html;
  }
}