import styled from "styled-components";
import { useAlert, setAlert } from "../context/AlertManager";
import { Button } from "./Button";
import { Alert } from "../Utils/Alert";

export const AlertDialog = () => {

  const alert = useAlert();

  const actions = [];

  for ( let action of alert.actions ) {
    actions.push(
      <Button zIndex={ 10 } enabled={ true } textButton={ true } text={ action.text.toUpperCase() } onClick={ action.onClick }/>
    );
  }

  return (
    <AlertDialogContainer empty={ alert.empty }>
      <Dialog empty={ alert.empty }>
        <Title>{ alert.title.toUpperCase() }</Title>
        <Body>{ alert.message }</Body>
        <ButtonRow>
          { actions }
          <Button zIndex={ 10 } enabled={ true } textButton={ true } text={ "OK" } onClick={ () => {
            alert.after();
            setAlert(Alert.toEmpty(alert));
            setTimeout(() => {
              // setAlert(Alert.createEmpty());
              setAlert(Alert.create("", true));
            }, 200);
          } }/>
        </ButtonRow>
      </Dialog>
      <Screen empty={ alert.empty }/>
    </AlertDialogContainer>
  );
}

const AlertDialogContainer = styled.div`
  height: 100vh;
  width: 100vw;
  z-index: 12;
  position: fixed;
  transition: 200ms linear;
  pointer-events: ${ props => props.empty ? "none" : "auto" };
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Screen = styled.div`
  background-color: black;
  height: 100vh;
  width: 100vw;
  opacity: ${ props => props.empty ? 0 : 0.4 };
  position: fixed;
  transition: 200ms linear;
  z-index: 0;
`;

const Dialog = styled.div`
  width: min(90%, 320px);
  background-color: white;
  border-radius: 10px;
  z-index: 3;
  display: flex;
  flex-direction: column;
  gap: 10px;
  opacity: ${ props => props.empty ? 0 : 1 };
  pointer-events: ${ props => props.empty ? "none" : "auto" };
  transition: 200ms linear;
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  margin: 0 10px;
`;

const Title = styled.text`
  font-size: 24px;
  font-family: var(--pure-material-font, "Bahnschrift", "Segoe UI", BlinkMacSystemFont, system-ui, -apple-system);
  font-weight: bold;
  color: ${ props => props.theme.primary };
  margin: 12px 0 0 14px;
`;

const Body = styled.text`
  font-size: 16px;
  font-family: var(--pure-material-font, "Bahnschrift", "Segoe UI", BlinkMacSystemFont, system-ui, -apple-system);
  color: black;
  margin: 0 0 0 14px;
`;