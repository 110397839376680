export const THEME = {
  dark: {
    title_font: "Berlin Sans",
    body_font: "Berlin Sans",
    background: "#303030",
    background_secondary: "#202020",
    on_background: "#424242",
    text_primary: "white",
    text_secondary: "rgba(255, 255, 255, 0.7)",
    text_disabled: "rgba(255, 255, 255, 0.5)",
    text_hint: "rgba(255, 255, 255, 0.5)",
    primary: "#3f51b5",
    primary_light: "rgb(101, 115, 195)",
    primary_dark: "rgb(44, 56, 126)",
    primary_text: "white",
    secondary: "#f50057",
    secondary_light: "rgb(247, 51, 120)",
    secondary_dark: "rgb(171, 0, 60)",
    secondary_text: "white",
    error: "#f44336",
    error_light: "#e57373",
    error_dark: "#d32f2f",
    error_text: "#FFFFFF",
    warning: "#FF9800",
    warning_light: "#FFB74D",
    warning_dark: "#F57C00",
    warning_text: "rgba(0, 0, 0, 0.87)",
    info: "#2196F3",
    info_light: "#64B5F6",
    info_dark: "#1976D2",
    info_text: "#ffffff",
    success: "#4CAF50",
    success_light: "#81C784",
    success_dark: "#388E3C",
    success_text: "rgba(0, 0, 0, 0.87)",
    divider: "rgba(255, 255, 255, 0.12)",
    outline: "#545258",
    outline_dark: "#29282b",
    shadow: "#202020",
  },
  light: {
    title_font: "Berlin Sans",
    body_font: "Berlin Sans",
    background: "#fafafa",
    background_secondary: "#ffffff",
    on_background: "#bfbfbf",
    text_primary: "rgba(0, 0, 0, 0.87)",
    text_secondary: "rgba(0, 0, 0, 0.54)",
    text_disabled: "rgba(0, 0, 0, 0.38)",
    text_hint: "#8d8d8dcc",
    primary: "#3f51b5",
    primary_light: "rgb(101, 115, 195)",
    primary_dark: "rgb(44, 56, 126)",
    primary_text: "white",
    secondary: "#f50057",
    secondary_light: "rgb(247, 51, 120)",
    secondary_dark: "rgb(171, 0, 60)",
    secondary_text: "white",
    error: "#f44336",
    error_light: "#e57373",
    error_dark: "#d32f2f",
    error_text: "#FFFFFF",
    warning: "#FF9800",
    warning_light: "#FFB74D",
    warning_dark: "#F57C00",
    warning_text: "rgba(0, 0, 0, 0.87)",
    info: "#2196F3",
    info_light: "#64B5F6",
    info_dark: "#1976D2",
    info_text: "#ffffff",
    success: "#4CAF50",
    success_light: "#81C784",
    success_dark: "#388E3C",
    success_text: "rgba(0, 0, 0, 0.87)",
    divider: "rgba(255, 255, 255, 0.12)",
    outline: "#19181b",
    outline_dark: "#4c4b50",
    shadow: "#202020",
  },
}

const main = {
  background: "#303030",
  dark: "#041C32",
  focus: "#fff9da",
  body: "#b2bdde",
  secondary: "#a1bdce",
  body_focus: "#cfe4ff",
  secondary_focus: "#bedbec",
  body_unfocus: "#8892b3",
  on_background1: "#064663",
  on_background2: "#106084",
  on_background3: "#0a5578",
  hover1: "#8599b2",
  hover_focus: "#b7b399",
  shadow: "#021117",
  unfocus: "#938F99",
  error: "#c82b24",
}
