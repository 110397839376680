import styled from "styled-components";
import { MapContainer, TileLayer, useMap, Marker, Popup, Polygon } from 'react-leaflet';
import { POLYGON } from "../data/polygon";
import { TextField } from "../components/TextField";
import { Button } from "../components/Button";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { AlertContext, setAlert } from "../context/AlertManager";
import { Alert } from "../Utils/Alert";
import MaterialIcon, { colorPalette } from 'material-icons-react';
import { useCookies } from 'react-cookie';


export const Map = ( props ) => {

  const [ cookies, setCookie, removeCookie ] = useCookies( [ 'history' ] );

  const [ enabled, setEnabled ] = useState( true );

  const [ state, setState ] = useState( { x: 1.3521, y: 103.8198, addr: "", inBoundary: false, showing: false } );

  const tf = useRef( null );

  const blueOptions = { color: "rgb(101, 115, 195)" };


  useEffect( () => {
    const params = new URLSearchParams( window.location.search );
    const postal = params.get( "postal" );
    if ( postal != null && postal.match( /[\d]{6}/ ) != null ) {
      const setValue = Object.getOwnPropertyDescriptor( window.HTMLInputElement.prototype, 'value' ).set;
      setValue.call( tf.current, postal );
      tf.current.dispatchEvent( new Event( 'input', { bubbles: true } ) );
      search();
    }
  }, [ tf ] );

  const search = async () => {
    if ( tf.current.value.match( /[\d]{6}/ ) != null ) {
      setEnabled( false );
      try {
        const postal = tf.current.value;
        const res = await axios.get( `https://boundary.josherlo.com/in-boundary?postal=${ postal }` );
        if ( res.data.found ) {
          setState( {
            x: res.data.loc.lat,
            y: res.data.loc.long,
            addr: res.data.addr,
            inBoundary: res.data.res,
            showing: true
          } );
          if ( cookies.history == null ) {
            setCookie( "history", btoa( `[{"${ postal }": "${ res.data.addr }"}]` ) );
          } else {
            try {
              const list1 = JSON.parse( atob( cookies.history ) );
              const list = JSON.parse( atob( cookies.history ) );
              for ( let i in list1 ) {
                const loc = list1[i];
                if ( Object.keys( loc )[0] == postal ) {
                  list.splice( i, 1 );
                  break;
                }
              }
              list.push( JSON.parse( `{"${ postal }": "${ res.data.addr }"}` ) );
              setCookie( "history", btoa( JSON.stringify( list ) ) );
            } catch ( e ) {
              setCookie( "history", btoa( `[{"${ postal }": "${ res.data.addr }"}]` ) );
            }
          }
        } else {
          setAlert( Alert.create( res.data.message, false, "Error" ) );
        }
      } catch ( e ) {
        console.error( e );
      } finally {
        setEnabled( true );
      }
    } else if ( tf.current.value == "" ) {
      setAlert( Alert.create( "Postal code cannot be empty!", false, "Error" ) );
    } else {
      setAlert( Alert.create( "Invalid postal code!", false, "Error" ) );
    }

  }

  const items = [];

  if (cookies.history == null) {
    items.push(<HistoryItem>No addresses in history!</HistoryItem>)
  } else {
    for ( let i of JSON.parse( atob( cookies.history ) ) ) {
      items.push(
        <HistoryItem onClick={ () => {
          window.location.href = `?postal=${Object.keys(i)[0]}`;
        }}>{ i[Object.keys( i )[0]] }</HistoryItem>
      );
    }
    items.push(<HistoryItem onClick={ () => {
      removeCookie("history", {path:'/'});
    } } >
      Clear History
    </HistoryItem>)
  }

  return (
    <AppContainer>
      <MapContainerStyled center={ [ 1.3521, 103.8198 ] } zoom={ 11 } zoomControl={ false }>
        <TileLayer style={ { zIndex: 0 } }
                   attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="http://cartodb.com/attributions">CartoDB</a>, CartoDB <a href ="http://cartodb.com/attributions">attributions</a>'
                   url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
        />
        { state.showing ?
          <Marker position={ [ state.x, state.y ] }>
            <Popup>{ state.addr }</Popup>
          </Marker> : <div/> }
        <Polygon pathOptions={ blueOptions } positions={ POLYGON }/>
      </MapContainerStyled>
      <TextField label="POSTAL CODE" width={ 166 } type={ "number" } ref={ tf }/>
      <Button text="SEARCH" enabled={ enabled } onClick={ search } fontSize={ 22 } zIndex={ 11 } left={ 222 } top={ 38 }
              position={ "fixed" }/>
      <Result showing={ state.showing }
              in={ state.inBoundary }>{ state.inBoundary ? "In boundary!" : "Not in boundary!" }</Result>
      <HistoryWrapper>
        <MaterialIcon icon="history" color={ colorPalette.grey._900 } size={ 30 }/>
        <HistoryPopup showing={ state.showing }>
          {items}
        </HistoryPopup>
      </HistoryWrapper>
    </AppContainer>
  );
}

const AppContainer = styled.div`
  height: 100vh;
  width: 100vw;

`;

const MapContainerStyled = styled( MapContainer )`
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: 11;
`;

const Result = styled.text`
  position: fixed;
  display: ${ props => props.showing ? "inline-block" : "none" };
  font-size: 24px;
  font-family: var(--pure-material-font, "Bahnschrift", "Segoe UI", BlinkMacSystemFont, system-ui, -apple-system);
  font-weight: bold;
  color: ${ props => props.in ? props.theme.success : props.theme.error };
  z-index: 11;
  top: 90px;
  left: 24px;
`;

const HistoryWrapper = styled.button`
  width: 30px;
  height: 30px;
  z-index: 11;
  position: fixed;
  left: 302px;
  top: 86px;
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  background-color: transparent;

  &:hover {
    cursor: pointer;
  }
`;

const HistoryPopup = styled.div`
  position: absolute;
  z-index: 12;
  background-color: white;
  border-radius: 10px;
  display: none;
  padding: 10px 10px 8px 10px;
  margin: 15px 0 0 -180px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);

  ${ HistoryWrapper }:focus-within & {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
`;

const HistoryItem = styled.div`
  width: 180px;
  position: relative;

  &:not(:last-child):after {
    content: "";
    position: absolute;
    width: calc(100% - 10px);
    height: 2px;
    background-color: rgba(177, 177, 177, 0.55);
    bottom: 0;
    left: 5px;
    top: calc(100% + 4px);
    border-radius: 1px;
  }
`;