import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Map } from './pages/Map';
import reportWebVitals from './reportWebVitals';
import { CookiesProvider } from "react-cookie";
import styled, { ThemeProvider } from "styled-components";
import { THEME } from "./data/Theme";
import { AlertDialog } from "./components/AlertDialog";

const root = ReactDOM.createRoot( document.getElementById( 'root' ) );

const Copyright = styled.text`
  position: fixed;
  bottom: 5px;
  left: 5px;
  font-size: 11px;
  z-index: 100;
`;


root.render(
  <React.StrictMode>
    <CookiesProvider>
      <ThemeProvider theme={ THEME.light }>
        <Copyright>&copy; 2022 Lo Sze Ee, Josher. Only for use within TTSH</Copyright>
        <AlertDialog />
        <Map />
      </ThemeProvider>
    </CookiesProvider>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
