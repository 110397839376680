import { useState, useEffect, useRef } from "react";
import styled from "styled-components";

export const Button = (props) => {

    const [ coords, setCoords ] = useState({ x: -1, y: -1 });
    const [ num, updateState ] = useState(0);
    const [ rippling, setRippling ] = useState(false);
    const [ playing, setPlaying ] = useState(false);
    const btn = useRef(null);

    useEffect(() => {
        if (btn != null) {
            updateState(num + 1);
        }
    }, [ btn ]);

    return (
        <StyledButton { ...props } width={ props.width || "auto" } type={ props.type || "default" } onMouseDown={(e) => {
            const rect = e.target.getBoundingClientRect();
            setCoords({ x: e.clientX - rect.left - 20, y: e.clientY - rect.top - 20 });
            setRippling(true);
            setPlaying(true);
            setTimeout(() => {
                setPlaying(false);
                setCoords({ x: -1, y: -1 });
            }, 400);
        }} onMouseUp={(e) => {
            setRippling(false);
            props.onClick(e);
        }} onMouseLeave={(e) => {
            setRippling(false);
        }} ref={btn}>
            { playing ? <Ripple textButton={ props.textButton } left={ coords.x } top={ coords.y } width={ btn.current.offsetWidth } height={ btn.current.offsetHeight }/> : '' }
            { rippling ? <FullRipple textButton={ props.textButton } width={ btn.current.offsetWidth } height={ btn.current.offsetHeight }/> : '' }
            { props.text }
        </StyledButton>
    );
};

const StyledButton = styled.button`
  position: ${ props => props.position || "relative" };
  width: ${ props => `${ props.width - 30 }px` };
  height: auto;
  border: none;
  outline: none;
  background-color: ${ props => props.textButton ? "transparent" : (props.enabled ? props.theme.primary_light : props.theme.primary) };
  pointer-events: ${ props => props.enabled ? "all" : "none" };
  align-content: center;
  justify-content: center;
  padding: 7px 12px 5px 12px;
  border-radius: 5px;
  transition: 250ms ease-in-out;
  font-family: var(--pure-material-font, "Bahnschrift", "Segoe UI", BlinkMacSystemFont, system-ui, -apple-system);
  font-size: ${ props => `${ props.fontSize || 16 }px` };
  font-weight: bold;
  color: ${ props => props.textButton ? props.theme.primary : (props.enabled ? props.theme.primary_text : "rgba(255, 255, 255, 0.7)" ) };
  overflow: hidden;
  box-shadow: ${ props => props.textButton ? "none" : "0 0 3px #a9a9a9, 0 2px 5px #bdbdbd" };
  z-index: ${ props => props.zIndex };
  left: ${ props => `${ props.left || 0 }px` };
  top: ${ props => `${ props.top || 0 }px` };

  & > .content {
    color: green;
    position: relative;
    z-index: 2;
  }

  &:hover {
    box-shadow: ${ props => props.textButton ? "none" : "0 0 3px #a9a9a9, 0 3px 8px #b1b1b1" };
  }
  
  &:hover {
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.4;
  }

  &:hover:disabled {
    cursor: default;
  }

`

const FullRipple = styled.span`
  width: ${ props => `${ props.width }px` };
  height: ${ props => `${ props.height }px` };
  left: 0;
  top: 0;
  position: absolute;
  background: ${ props => props.textButton ? props.theme.primary_light : "white" };
  display: block;
  content: "";
  opacity: 0;
  animation-duration: 400ms;
  animation-name: rippleEffect;
  animation-fill-mode: forwards;
  animation-timing-function: linear;

  @keyframes rippleEffect {
    0% {
      opacity: 0;
    }
    60% {
      opacity: 0;
    }
    100% {
      opacity: 0.2;
    }
  }
`;

const Ripple = styled.span`
  width: 40px;
  height: 40px;
  position: absolute;
  background: ${ props => props.textButton ? props.theme.primary_light : "white" };
  display: block;
  content: "";
  border-radius: 9999px;
  opacity: 0.2;
  animation-duration: 400ms;
  animation-name: fullRipple;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  left: ${ props => `${ props.left }px` };
  top: ${ props => `${ props.top }px` };

  @keyframes fullRipple {
    0% {
      width: 40px;
      height: 40px;
      left: ${ props => `${ props.left }px` };
      top: ${ props => `${ props.top }px` };
    }
    60% {
      width: ${ props => `${ props.width * 2}px` };
      height: ${ props => `${ props.height * 2}px` };
      left: ${ props => `-${ props.width / 2 }px` };
      top: ${ props => `-${ props.height / 2 }px` };
      opacity: 0.2;
    }
    100% {
      width: ${ props => `${ props.width * 2}px` };
      height: ${ props => `${ props.height * 2}px` };
      left: ${ props => `-${ props.width / 2 }px` };
      top: ${ props => `-${ props.height / 2 }px` };
      opacity: 0;
    }
  }
`;